import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://64ca506fa4d0d3a71df88c4f15ed8931@o149900.ingest.us.sentry.io/4507012809097216",
  debug: false,
  environment: "production",
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 0.1,
  sampleRate: 0.6,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.2,
  replaysOnErrorSampleRate: 0.8,
});